
<div class="background" *ngIf="isLoading">
  <div id="loading-wanyudo">
    <img src="../../assets/img/wanyuudou.gif">
    <h1>
      <span>L</span>
      <span>o</span>
      <span>a</span>
      <span>d</span>
      <span>i</span>
      <span>n</span>
      <span>g</span>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </h1>
  </div>
</div>
