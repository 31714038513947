<div id="bg-casino" class="background">
  <!-- Move Room -->
  <div class="move-vec move-down" (click)="onMove('livingRoom')">
    <p>Living Room</p>
    <img src="../../../assets/img/move_down.png" alt="Living Room">
  </div>

  <!-- Charactor -->
  <div id="jessie" class="characters" (click)="openSerifs('Casino', 'Jessie-Khan')">
    <img src="../../../assets/img/Jessie-Khan_map.png" alt="Jessie-Khan">
  </div>

</div>
