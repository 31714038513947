
<nav class="d-flex justify-content-between sticky-top">

  <!-- LOGO -->
  <div class="navbar-brand">
    <a href="home">
      <img src="../../../assets/img/Logo_shortversion.png" alt="logo">
    </a>
  </div>
  <!-- menu -->
  <div class="d-flex justify-content-between">
    <!-- Sound -->
    <div id="sound" class="d-flex align-items-center">
      <div>
        <span id="vol_value">{{volume_display}}</span>
      </div>
      <div id="sound-icon">
        <img src="../../../assets/img/volume.png" alt="volume" width="20" height="20">
      </div>
      <input id="sound_input" type="range" name="sound" min="0" max="100">
    </div>
    <!-- SiteMap -->
    <div class="btn-group" dropdown>
      <button id="sitemap" dropdownToggle type="button" class="btn dropdown-toggle"
              aria-controls="sitemap-dropdown">
        <img src="../../../assets/img/icon_sitemap.png" width="30" height="30" alt="sitemap">
        <span class="d-none d-sm-inline">Site Map</span>
      </button>
      <ul id="sitemap-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right nav-dropdown"
          role="menu" aria-labelledby="sitemap">
        <li class="page-category">Creator</li>
        <li role="menuitem" (click)="transitPage('aboutme')"><span class="dropdown-item">About Me</span></li>
        <li role="menuitem" (click)="transitPage('contactme')"><span class="dropdown-item">Contact Me</span></li>
        <li class="divider dropdown-divider"></li>

        <li class="page-category">Site</li>
        <li role="menuitem" (click)="transitPage('concept')"><span class="dropdown-item">Concept</span></li>
        <li role="menuitem" (click)="transitPage('character-list')"><span class="dropdown-item">Character List</span></li>
        <li class="divider dropdown-divider"></li>

        <li role="menuitem"><span class="dropdown-item" (click)="transitHome()">- return to home -</span></li>
      </ul>
    </div>
  </div>

</nav>
