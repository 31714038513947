<!-- Navication bar -->
<app-nav [sound_setting]="page_sound" (volume_changed)="preserveVolume($event)"></app-nav>

<!-- Common header zone -->
<div class="header-belt">
  <img src="../../../assets/img/Clouds_004.png" id="cloud0" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_008.png" id="cloud1" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_004.png" id="cloud2" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_007.png" id="cloud3" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_004.png" id="cloud4" class="cloud" alt="cloud">
</div>
<div class="outer-diamond">
  <div class="header-diamond">
    <div>
      <p>Character List</p>
      <hr>
      <h1>妖怪図鑑</h1>
    </div>
  </div>
</div>

<!-- Dark roof zone -->
<div class="stage-header">
  <div></div>
</div>

<div class="main-container">
  <!-- background parts -->

  <div class="character-frame">
    <!-- background part -->
    <div class="backstage">
      <div class="stage-object">
        <!-- character part -->
        <div class="character-part">
          <div class="character-name">
            <div class="outer-name">
              <div class="inner-name">
                <h1>{{character_name}}</h1>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column-reverse flex-sm-row align-items-sm-stretch align-items-center character-container">
            <div class="main-image">
              <img [src]="main_image_path" alt="character">
            </div>
            <div class="character-exp">
              <div class="inner-exp">
                {{character_exp}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Curtain -->
      <div class="curtain"></div>
    </div>
  </div>

  <!-- Dark stage zone -->
  <div class="stage-footer">
    <div></div>
  </div>

  <!-- Character select -->
  <div class="character-select">
    <ng-container *ngFor="let d of character_data;">
      <div id="{{ 'character_' + d.id }}" class="select-background" (click)="setCurrentCharacter(d.id)">
        <img [src]="d.select_image_file" alt="character">
      </div>
    </ng-container>
  </div>
</div>
