<div id="bg-work-shop" class="background">
  <!-- Move Room -->
  <div class="move-vec move-right" (click)="onMove('livingRoom')">
    <p>Living Room</p>
    <img src="../../../assets/img/move_right.png" alt="Living Room">
  </div>

  <!-- Characters -->
  <div id="ittan-momen" class="characters" (click)="openSerifs('WorkShop', 'Ittan-momen')">
    <img src="../../../assets/img/Ittan-momen_map.png" alt="Ittan-momen">
  </div>

</div>
