<div class="background">
  <div id="setting_wrappar">
    <h4>Configure before entering</h4>
    <hr>
    <form #confForm="ngForm" (ngSubmit)="configure()">
      <div id="setting_area">
        <!-- TODO: i18n -->
        <!-- <div class="lang form-check-inline">
          <label>Langage:</label>
          <div class="btn-group">
            <ng-container *ngFor="let la of langs">
              <label class="btn btn-outline-primary">
                <input type="radio" name="langs"
                [(ngModel)]="lang_selected"
                [value]="la.value">
                {{la.label}}
              </label>
            </ng-container>
          </div>
        </div> -->

        <div class="sound">
          <label class="item">Sound:</label>
          <div class="btn-group">
            <ng-container *ngFor="let s of sounds">
              <label class="btn option">
                <input type="radio" name="sounds"
                [(ngModel)]="sound_selected"
                [value]="s.value"
                [checked]="true">
                {{s.label}}
              </label>
            </ng-container>
          </div>
        </div>
      </div>
      <hr>
      <div id="button_area">
        <input type="submit" value="OK" class="btn">
      </div>
    </form>
  </div>

</div>
