<div id="serif-container">
  <div id="serif-content" class="d-flex flex-column">
    <!-- Display Character -->
    <div id="character-box" class="d-flex justify-content-center">
      <img [src]="img_chara" alt="charactor">
    </div>

    <!-- Serif Area -->
    <div id="serif-box">
      <div id="upper-part">
        <div id="name-area">{{name_chara}}</div>
        <div id="cancel" (click)="onCancel()"></div>
      </div>
      <div id="serif-area" (click)="onTalk()">
        <span id="serif-span">{{current_serif}}</span>
      </div>
    </div>
  </div>
</div>

<!-- Selection Modal -->
<div *ngIf="isSelection" bsModal #selectionModal="bs-modal"
  class="modal fade" [config]="{ show: true, backdrop: 'static' }">
  <div class="d-flex flex-column justify-content-center">
    <ng-container *ngFor="let s of selections">
      <div class="choice d-flex justify-content-center align-items-center"
        (click)="selectRoute(s.nextId)">
        {{s.option}}
      </div>
    </ng-container>
  </div>
</div>
