<!-- Navication bar -->
<app-nav [sound_setting]="page_sound" (volume_changed)="preserveVolume($event)"></app-nav>
<!-- Common header zone -->
  <div class="header-belt">
    <img src="../../../assets/img/Clouds_004.png" id="cloud0" class="cloud" alt="cloud">
    <img src="../../../assets/img/Clouds_008.png" id="cloud1" class="cloud" alt="cloud">
    <img src="../../../assets/img/Clouds_004.png" id="cloud2" class="cloud" alt="cloud">
    <img src="../../../assets/img/Clouds_007.png" id="cloud3" class="cloud" alt="cloud">
    <img src="../../../assets/img/Clouds_004.png" id="cloud4" class="cloud" alt="cloud">
  </div>
  <div class="outer-diamond">
    <div class="header-diamond">
      <div>
        <p>About Me</p>
        <hr>
        <h1>自己紹介</h1>
      </div>
    </div>
  </div>

<div class="main-content">
  <!-- Ice breaking -->
  <div id="greeting_area">
    <p data-aos="fade" data-aos-duration="1500" data-aos-delay="500">
      Kia Ora! I've been waiting for you!!
      My name is Takeru Tomii, call me Tommy!
      I'm a Full-Stack Web Developer.
      Mainly I'm in charge of frontend and backend seamlessly.
      I was studying Search Algorithms in University of Tsukuba in Japan
      and have a Bachelor's Degree of Information Science.
    </p>
    <p data-aos="fade" data-aos-duration="1500" data-aos-delay="1000">
      This site is made for my portfolio and indicate how much I can supply your customer
      fabulous user experiences through my techniques.
      I sympathize how anxious you feel to get down to the business with a stranger who have met at first.
      But you will notice you don't have to worry, at least after reading my information below.

      If you get intrigued by my work, let's check out althrough this site!
    </p>
  </div>

  <!-- Introduction -->
  <div id="status_area">
    <div class="tatami-header"></div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <h1 class="section-title" data-aos="flip-left">Status</h1>
      </div>

      <div class="row">
        <div class="left-half col-lg-4 d-flex justify-content-center">
          <div id="card" data-aos="fade-left">
            <div id="inner-box">
              <div id="rarelity">★★★★★</div>
              <div id="portrait">
                <img [src]="portrait_path" alt="portrait">
              </div>
              <div id="strength">
                <p id="str_title">STR</p>
                <p id="str_val">{{str_val}}</p>
                <p id="str_unit">{{str_unit}}</p>
              </div>
              <div id="card_name">Tommy</div>
              <div id="attribute" (click)="setPortraitAttr()">
                <img [src]="attr_path" alt="attribute">
              </div>
            </div>
          </div>
        </div>

        <div class="right-half col-lg-8 d-flex flex-column justify-content-between"
          data-aos="fade-right">
          <div id="comment">
            {{comment_content}}
          </div>

          <table id="profile">
            <thead>
              <tr>
                <td class="strongly">Profile</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td class="notice">Name</td>
                <td>Takeru Tomii</td>
              </tr>
              <tr>
                <td class="notice">Lv</td>
                <td><span>{{current_age}}</span> (years old)</td>
              </tr>
              <tr>
                <td class="notice">Sex</td>
                <td>Male</td>
              </tr>
              <tr>
                <td class="notice">Birth Place</td>
                <td>Japan</td>
              </tr>
              <tr>
                <td class="notice">Language</td>
                <td>Japanese(Native) / English(Business Level)</td>
              </tr>
            </tbody>
          </table>

          <table id="equipment">
            <thead>
              <tr>
                <td class="strongly">Equipment</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a [href]="url_ipa_exam">Fundamental Information Technology Engineer Examination</a>
                </td>
                <td>(2017/10)</td>
              </tr>
              <tr>
                <td>
                  <a [href]="url_ipa_exam">Applied Information Technology Engineer Examination </a>
                </td>
                <td>(2018/10)</td>
              </tr>
              <tr>
                <td>
                  <a [href]="url_python_exam">Python 3 Certified Engineer Basic Examination</a>
                </td>
                <td>(2019/11)</td>
              </tr>
              <tr>
                <td>
                  <a href="#">Toefl iBT Score </a> 79 points
                </td>
                <td>(2021/08)</td>
              </tr>
            </tbody>
          </table>

          <div id="expression">
            <p id="speacies" class="strongly">{{speacies}}</p>
            <p id="detail">
              {{exp_content}}
           </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- parallax background -->
  <div id="horror_eye" class="horror-eye parallax-bg"></div>

  <!-- Skill Sets -->
  <div id="skills_area" class="skills">
    <div id="skills_container">
      <div class="d-flex justify-content-center">
        <h1 class="section-title" data-aos="flip-left">Skills</h1>
      </div>
      <div data-aos="fade-right">
        <div class="skill-title">～  Legend  ～</div>
        <table id="legend" class="table">
          <tr>
            <td>
              <div class="d-flex flex-column-reverse flex-sm-row align-items-center">
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
              </div>
            </td>
            <td class="align-middle">Expert</td>
            <td class="d-none d-lg-table-cell">Leave it to me.</td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-column-reverse flex-sm-row align-items-center">
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_off"></div>
              </div>
            </td>
            <td class="align-middle">Advanced</td>
            <td class="d-none d-lg-table-cell">Better than anyone else in high probability.</td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-column-reverse flex-sm-row align-items-center">
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_off"></div>
                <div class="gauge_off"></div>
              </div>
            </td>
            <td class="align-middle">Intermediate</td>
            <td class="d-none d-lg-table-cell">Basically I can handle it by myself. </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-column-reverse flex-sm-row align-items-center">
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_off"></div>
                <div class="gauge_off"></div>
                <div class="gauge_off"></div>
              </div>
            </td>
            <td class="align-middle">Elementary</td>
            <td class="d-none d-lg-table-cell">Know a little. But possibly need a help in deep level.</td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-column-reverse flex-sm-row align-items-center">
                <div class="gauge_on">
                  <picture>
                    <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                    <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                  </picture>
                </div>
                <div class="gauge_off"></div>
                <div class="gauge_off"></div>
                <div class="gauge_off"></div>
                <div class="gauge_off"></div>
              </div>
            </td>
            <td class="align-middle">Begginer</td>
            <td class="d-none d-lg-table-cell">Under studying. Not bussiness level at current.</td>
          </tr>
        </table>
      </div>

      <carousel [interval]="stop_sliding">
        <!-- Skill Slides -->
        <slide *ngFor="let s of slides;">
          <div data-aos="fade-right">
            <div class="skill-title">{{s.title}}</div>
            <table class="regular-skills table">
              <tr *ngFor="let r of s.rows;">
                <td class="align-middle">{{r.item}}</td>
                <td>
                  <div class="d-flex flex-column-reverse flex-sm-row align-items-center">
                    <div [ngClass]="r.rate > 0 ? 'gauge_on' : 'gauge_off'">
                      <picture>
                        <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                        <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                      </picture>
                    </div>
                    <div [ngClass]="r.rate > 1 ? 'gauge_on' : 'gauge_off'">
                      <picture>
                        <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                        <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                      </picture>
                    </div>
                    <div [ngClass]="r.rate > 2 ? 'gauge_on' : 'gauge_off'">
                      <picture>
                        <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                        <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                      </picture>
                    </div>
                    <div [ngClass]="r.rate > 3 ? 'gauge_on' : 'gauge_off'">
                      <picture>
                        <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                        <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                      </picture>
                    </div>
                    <div [ngClass]="r.rate > 4 ? 'gauge_on' : 'gauge_off'">
                      <picture>
                        <source media="(max-width: 576px)" srcset="../../../assets/img/kunai-1-hit.png">
                        <img src="../../../assets/img/shuriken-1-hit.png" alt="Gauge">
                      </picture>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </slide>
      </carousel>
    </div>
  </div>

  <!-- parallax background -->
  <div id="horror_mouths" class="horror-mouths">
    <img id="horror-mouth" src="../../../assets/img/mouth_smile.png" alt="Mouth">
    <img id="horror-hand" src="../../../assets/img/Hand.png" alt="hand">
  </div>


  <!-- Education and Personal history -->
  <div id="career_area">
      <h1 class="section-title" data-aos="flip-left">Career</h1>
    <details data-aos="fade-down">
      <summary>
        <div class="makimono">
          <div class="title">Education and Personal History</div>
          <div class="right-roll">
            <div class="makimono-core">
              <div class="left-roll"></div>
              <div class="right-roll"></div>
            </div>
          </div>
        </div>
      </summary>


      <div class="makimono-inner">
        <div class="paper">
          <table id="history" class="container table-borderless">
            <tr>
              <td>2017 March</td>
              <td class="d-none d-md-table-cell">
                <div class="event-spot">
                  <div class="spot"></div>
                  <div class="v-line"></div>
                </div>
              </td>
              <td>
                Graduated from University of Tsukuba.<br>
                After finishing my graduation paper about search algorithm,<br>
                I obtained a bachelor degree of Information Engineering.
              </td>
            </tr>
            <tr>
              <td>2017</td>
              <td class="d-none d-md-table-cell">
                <div class="event-spot">
                  <div class="spot"></div>
                  <div class="v-line"></div>
                </div>
              </td>
              <td>
                After training for new employees,<br>
                I engaged in a project of Artificial Intelligence Platform.<br>
                I was in charge of API side.<br>
                There for I learned Python.
              </td>
            </tr>
            <tr>
              <td>2018</td>
              <td class="d-none d-md-table-cell">
                <div class="event-spot">
                  <div class="spot"></div>
                  <div class="v-line"></div>
                </div>
              </td>
              <td>
                This term mainly I engaged in some short projects based on Angular.<br>
                I absorbed a lot of knowledge about front side here.<br>
                Those experiences helped a lot to create this portfolio.
              </td>
            </tr>
            <tr>
              <td>2019～2022</td>
              <td class="d-none d-md-table-cell">
                <div class="event-spot">
                  <div class="spot"></div>
                  <div class="v-line"></div>
                </div>
              </td>
              <td>
                I engaged in some projects of an Azure-based service.<br>
                Mainly I'm in charge of screens made of ASP.NET MVC,<br>
                but also backgrounds like Azure functions or Python-based proxy system.
              </td>
            </tr>
            <tr>
              <td>2023～2024</td>
              <td class="d-none d-md-table-cell">
                <div class="event-spot">
                  <div class="spot final-spot"></div>
                </div>
              </td>
              <td>
                I engaged in the development of flight optimisation system in civil aviation field.<br>
                I contributed to enhance its UI and API by Angular and .Net experience.<br>
                My commercial experience in New Zealand had started this time.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </details>
    <h1 class="click-it">▲ Click  it!</h1>
    <div class="tatami-footer" style="position: absolute;bottom: 0;"></div>
  </div>

  <!-- Move other pages -->
  <div id="exit">
    <div id="akabeko" class="charactors d-flex justify-content-center"
    (click)="openSerifs('AboutMe', 'Akabeko')">
      <img src="../../../assets/img/Akabeko_map.png" alt="Akabeko">
      <div class="bubble">
        <p>Yipe! You got me!</p>
      </div>
    </div>
  </div>

</div>
