<div id="bg-tea-room" class="background">
  <!-- Move Room -->
  <div class="move-vec move-left" (click)="onMove('livingRoom')">
    <p>Living Room</p>
    <img src="../../../assets/img/move_left.png" alt="Living Room">
  </div>

  <div class="move-vec move-up" (click)="onMove('garden')">
    <p>Garden</p>
    <img src="../../../assets/img/move_up.png" alt="Garden">
  </div>

</div>
