<div id="bg-title" class="background">
  <div class="house">
    <picture>
      <source media="(max-width: 576px)" srcset="../../assets/img/mansion_mobile.png">
      <img src="../../assets/img/mansion_pc.png" alt="mansion">
    </picture>
  </div>
  <div class="lightnings">
    <img id="lightning1" src="../../assets/img/lightning_002.png" alt="lightning">
    <img id="lightning2" src="../../assets/img/lightning_003.png" alt="lightning">
    <img id="lightning3" src="../../assets/img/lightning_002.png" alt="lightning">
    <img id="lightning4" src="../../assets/img/lightning_004.png" alt="lightning">
    <img id="lightning5" src="../../assets/img/lightning_002.png" alt="lightning">
  </div>
  <div id="title-logo">
    <img src="../../assets/img/Logo_longversion.png" alt="logo">
  </div>
  <button id="enter" type="button" class="btn" (click)="transitHome()">
    Enter here...
  </button>
</div>

