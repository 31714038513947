<!-- Navication bar -->
<app-nav [sound_setting]="page_sound" (volume_changed)="preserveVolume($event)"></app-nav>

<!-- Common header zone -->
<div class="header-belt">
  <img src="../../../assets/img/Clouds_004.png" id="cloud0" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_008.png" id="cloud1" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_004.png" id="cloud2" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_007.png" id="cloud3" class="cloud" alt="cloud">
  <img src="../../../assets/img/Clouds_004.png" id="cloud4" class="cloud" alt="cloud">
</div>
<div class="outer-diamond">
  <div class="header-diamond">
    <div>
      <p>Contact Me</p>
      <hr>
      <h1>問い合せ</h1>
    </div>
  </div>
</div>
<div id="bg-washi" class="row">
  <!-- charactor  -->
  <div id="chara_side" class="d-flex col-lg-4 flex-column justify-content-start align-items-center">
    <div class="bubble">
      <p>{{bubble_content}}</p>
    </div>
    <div id="akabeko" class="charactors d-flex" (click)="openSerifs('ContactMe', 'Akabeko')">
      <img src="../../../assets/img/Akabeko_map.png" alt="Akabeko">
    </div>
  </div>
  <!-- input form  -->
  <div id="form_side" class="d-flex col-lg-8 flex-column justify-content-start">
    <form name="Inquiry" #Inquiry="ngForm" novalidate>
      <!-- Name -->
      <div class="form-group">
        <label for="name">Name  <span class="badge badge-pill badge-danger">Required</span></label>
        <input type="text" class="form-control" id="name" name="name" placeholder="John Doe"
          [(ngModel)]="inquiry.name" #name="ngModel" required maxlength="100"
          [disabled]="this.isSend">
      </div>
      <span *ngIf="name.errors?.required && name.dirty" class="err-msg">
        Name is required.
      </span>
      <span *ngIf="name.errors?.maxlength && name.dirty" class="err-msg">
        Input Name in 100 characters.
      </span>
      <!-- Email -->
      <div class="form-group">
        <label for="mail">E-mail address  <span class="badge badge-pill badge-danger">Required</span></label>
        <input type="email" class="form-control" id="mail" name="mail" placeholder="example@xxx.mail.com"
          [(ngModel)]="inquiry.mail" #mail="ngModel" required email
          [disabled]="this.isSend">
      </div>
      <span *ngIf="mail.errors?.required && mail.dirty" class="err-msg">
        E-mail address is required.
      </span>
      <span *ngIf="mail.errors?.email && mail.touched" class="err-msg">
        Invalid E-mail address. Input it in the correct format.
      </span>
      <!-- Message -->
      <div class="form-group">
        <label for="message">Message  <span class="badge badge-pill badge-danger">Required</span></label>
        <textarea class="form-control" id="message" name="message" placeholder="You are hired." rows="10"
          [(ngModel)]="inquiry.message" #message="ngModel" required maxlength="2000"
          [disabled]="this.isSend"></textarea>
      </div>
      <span *ngIf="message.errors?.required && message.dirty" class="err-msg">
        Message is required.
      </span>
      <span *ngIf="name.errors?.maxlength && name.dirty" class="err-msg">
        Input Name in 2000 characters.
      </span>
      <!-- Send Button -->
      <div class="form-group">
        <button id="send" type="button" (click)="sendInquiry()"
        [disabled]="Inquiry.invalid || this.isSend">
          {{send_button_content}}
        </button>
      </div>
    </form>
  </div>
</div>
