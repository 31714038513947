<div id="error_background">
  <div id="title_gameover">
    <img src="../../../assets/img/title_gameover.png" alt="game over">
  </div>
  <div id="exp">
    <h3>Cause of Death</h3>
    <p>{{cause_of_death}}</p>
  </div>
  <div id="grave">
    <img src="../../../assets/img/broken_grave.png" alt="grave">
  </div>
  <div id="restart" (click)="restart()">Restart</div>
</div>
