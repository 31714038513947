<div id="bg-living-room" class="background">
  <!-- Move Room -->
  <div class="move-vec move-left"  (click)="onMove('workShop')">
    <p>Work Shop</p>
    <img src="../../../assets/img/move_left.png" alt="Work Shop">
  </div>

  <!-- <div class="move-vec move-right"  (click)="onMove('teaRoom')">
    <p>Tea Room</p>
    <img src="../../../assets/img/move_right.png" alt="Tea Room">
  </div> -->

  <div class="move-vec move-up"  (click)="onMove('casino')">
    <p>Casino</p>
    <img src="../../../assets/img/move_up.png" alt="Casino">
  </div>

  <!-- Character -->
  <div id="eye-mirror" class="characters" (click)="openSerifs('LivingRoom', 'Eye-mirror')">
    <img src="../../../assets/img/Eye-mirror_neutral.png" alt="Eye-mirror">
  </div>


  <div id="tommy" class="characters" (click)="openSerifs('LivingRoom', 'Tommy')">
    <img src="../../../assets/img/Tommy_map.png" alt="Tommy">
  </div>

</div>
